export default {
  masterApplications: {
    id: null,
    name: null,
  },
  masterPriorityStatus: {
    id: null,
    name: null,
    color_label: null,
  },
  masterRequestStatus: {
    id: null,
    name: null,
    flag: null,
    description: null,
  },
  masterAccessMenu: {
    id: null,
    nik: null,
    menu: null,
    selected_menu: null,
    admin: null,
    view_only: null,
    welcome_redirect: null,
  },
  login: {
    nik: null,
    password: null,
  },
  master_menu: {
    id: null,
    title: null,
    route: null,
    icon: null,
    children: null,
    children_lv2: null,
    children_lv3: null,
  },
  memo: {
    title: null,
    description: null,
    due_date: null,
    priority_status: null,
    attachment: null,
    application_id: null,
    flag: null,
    requested_by: null,
  },
  request_detail: {
    request_code: null,
    application_name: null,
    title: null,
    description: null,
    requested_by: null,
    requester_name: null,
    priority_status: null,
    status: null,
    attachment: null,
    application_id: null,
    requested_time: null,
  },
  commentary: {
    request_code: null,
    description: null,
    attachment: null,
  },
  filter_chart: {
    month: null,
    year: null,
    sts: null
  }
}
